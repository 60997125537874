import React from 'react'
import './Brand.scss';

export default function Brand() {
  return (
    <div className='brand'>
    <div className='text'>
     <h1>Join the waitlist</h1>
     <h2>Be among the first to experience seamless delivery solutions across Nigeria. Sign up now to get early access to WeDel – your all-in-one platform for food delivery, ride-sharing, and package delivery.</h2>
     </div>
     
      <div className='Icons'>
        <div className='iconImg'><img src="Images/Eat.png" alt="WeEat" /></div>
        <div className='iconImg'><img src="Images/Deliver.png" alt="WeEat" /></div>
        <div className='iconImg'><img src="Images/Ride.png" alt="WeEat" /></div>
      </div>
      
    </div>
  )
}







/* <div className='brandLogo'>
<svg className='svg' width="440" height="95" viewBox="0 0 440 95" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M211.743 91.5844V0H268.48V14.7706H227.735V38.3198H264.238V52.4633H227.735V76.779H268.48V91.5496H211.743V91.5844Z" fill="#F58634"/>
<path d="M280.891 73.9224C280.891 62.5309 289.13 55.3895 303.766 54.2399L322.261 52.8465V51.453C322.261 43.0575 317.255 39.6784 309.502 39.6784C300.498 39.6784 295.492 43.4407 295.492 49.9551H282.49C282.49 36.5431 293.476 27.7644 310.232 27.7644C326.989 27.7644 337.106 36.787 337.106 53.9612V91.5495H323.721L322.574 82.3876C319.967 88.7626 311.588 93.152 301.958 93.152C288.852 93.152 280.821 85.5229 280.821 73.8527L280.891 73.9224ZM322.4 66.5371V63.2625L309.537 64.2728C300.046 65.1437 296.431 68.2789 296.431 73.2953C296.431 78.9388 300.185 81.6909 307.069 81.6909C316.455 81.6909 322.435 76.0474 322.435 66.5371H322.4Z" fill="#F58634"/>
<path d="M357.026 91.5843V42.4653H345.136V29.6804H357.026V10.3811H372.288V29.6804H384.282V42.4653H372.288V91.5843H357.026Z" fill="#F58634"/>
<path d="M403.506 72.7729C403.646 78.1725 407.643 81.5516 414.631 81.5516C421.619 81.5516 425.617 78.6602 425.617 74.1663C425.617 71.0311 423.983 68.7667 418.49 67.5126L407.365 64.8651C396.241 62.3568 390.852 57.0966 390.852 47.2031C390.852 35.0453 401.108 27.7993 415.361 27.7993C429.615 27.7993 438.619 35.8116 438.723 47.8301H424.226C424.087 42.5699 420.611 39.1908 414.736 39.1908C408.86 39.1908 405.245 41.9428 405.245 46.576C405.245 50.0945 407.991 52.324 413.241 53.5781L424.365 56.2257C434.725 58.5945 439.975 63.3671 439.975 72.8774C439.975 85.4184 429.337 93.1521 414.11 93.1521C398.883 93.1521 388.975 84.8959 388.975 72.738H403.472L403.506 72.7729Z" fill="#F58634"/>
<path d="M0.0252686 1.63721H16.6429L31.1398 49.9899C32.5304 55.0063 33.8863 60.1272 35.2768 68.1395C36.7717 59.9879 38.1623 55.2502 39.7615 49.9899L54.1542 1.63721H71.78L85.8945 49.9899C87.3894 55.1108 88.78 60.4059 90.2749 68.1395C92.0131 59.6395 93.2646 54.7276 94.6552 50.0944L109.396 1.63721H125.631L97.8884 93.2216H82.3832L62.9149 26.5451L43.0294 93.1868H27.2809L0.0252686 1.63721Z" fill="#00193D"/>
<path d="M122.919 62.2522C122.919 42.8485 135.539 29.4365 153.686 29.4365C171.833 29.4365 183.931 41.8382 183.931 61.1375V65.7707L137.416 65.91C138.528 76.8138 144.299 82.3179 154.416 82.3179C162.794 82.3179 168.287 79.0781 170.025 73.1908H184.14C181.498 86.7073 170.269 94.8589 154.138 94.8589C135.747 94.8589 122.884 81.447 122.884 62.2871L122.919 62.2522ZM137.798 56.1211H168.809C168.809 47.621 162.933 41.9776 153.825 41.9776C144.717 41.9776 139.328 46.8546 137.833 56.1211H137.798Z" fill="#00193D"/>
</svg>
</div>
 
 <div className='textDiv'>
    <h4>Experience the joy of delicious food, delivered fast.</h4>
 </div>  */
