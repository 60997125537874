import React from 'react';
import './Logo.scss';

export default function Logo() {
  return (
    // <div></div>
      <div className='logo'>
 
      <svg className='logo1' width="166" height="39" viewBox="0 0 166 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6277 0H36.0563C37.7555 0 39.1322 1.37285 39.1322 3.06552V17.4454H21.6277V0Z" fill="#F58634"/>
            <path d="M0 0C9.66761 0 17.5045 7.81045 17.5045 17.4454C7.83693 17.4454 0 9.63495 0 0Z" fill="#13890E"/>
            <path d="M0 21.5547H17.5045V39.0001H3.07591C1.37751 39.0001 0 37.6272 0 35.9338V21.5547Z" fill="#F58634"/>
            <path d="M21.6277 21.5547H39.1322V35.9346C39.1322 37.628 37.7547 39.0001 36.0563 39.0001H21.6277V21.5547Z" fill="#F58634"/>
            <path d="M50.8833 6.57568H55.505L59.5358 19.9431C59.9177 21.3284 60.3005 22.7481 60.6824 24.9641C61.0996 22.713 61.4815 21.3971 61.9331 19.9431L65.9287 6.57568H70.8282L74.7549 19.9431C75.1721 21.3627 75.554 22.8175 75.9712 24.9641C76.458 22.6092 76.8055 21.2582 77.1875 19.9774L81.2879 6.57568H85.8055L78.0915 31.8908H73.7828L68.362 13.4673L62.8371 31.8908H58.4588L50.8841 6.57568H50.8833Z" fill="#00193D"/>
            <path d="M85.04 23.337C85.04 17.9696 88.5496 14.2637 93.5876 14.2637C98.6257 14.2637 101.997 17.6919 101.997 23.025V24.3066L89.0708 24.3409C89.3831 27.3542 90.9821 28.8776 93.7966 28.8776C96.1251 28.8776 97.6536 27.9774 98.1397 26.3495H102.066C101.337 30.0897 98.2093 32.3409 93.727 32.3409C88.6192 32.3409 85.04 28.6357 85.04 23.337ZM89.1749 21.6396H97.7922C97.7922 19.2847 96.1587 17.7262 93.6229 17.7262C91.087 17.7262 89.5921 19.0765 89.1749 21.6396Z" fill="#00193D"/>
            <path d="M119.856 23.4063C119.856 18.0732 122.983 14.2636 128.161 14.2636C130.558 14.2636 132.678 15.2675 133.755 16.9648V6.12549H137.96V31.8907H134.068L133.791 29.2246C132.748 31.198 130.524 32.3408 127.918 32.3408C122.95 32.3408 119.857 28.7051 119.857 23.4063H119.856ZM133.72 23.2675C133.72 20.1856 131.809 18.0381 128.856 18.0381C125.903 18.0381 124.096 20.2199 124.096 23.2675C124.096 26.315 125.902 28.4625 128.856 28.4625C131.81 28.4625 133.72 26.3501 133.72 23.2675Z" fill="#00193D"/>
            <path d="M141.365 23.337C141.365 17.9696 144.874 14.2637 149.912 14.2637C154.95 14.2637 158.321 17.6919 158.321 23.025V24.3066L145.395 24.3409C145.708 27.3542 147.307 28.8776 150.121 28.8776C152.45 28.8776 153.978 27.9774 154.464 26.3495H158.391C157.661 30.0897 154.534 32.3409 150.051 32.3409C144.944 32.3409 141.365 28.6357 141.365 23.337ZM145.499 21.6396H154.117C154.117 19.2847 152.483 17.7262 149.947 17.7262C147.41 17.7262 145.916 19.0765 145.499 21.6396H145.499Z" fill="#00193D"/>
            <path d="M161.795 31.8907V6.12549H166V31.8907H161.795Z" fill="#00193D"/>
            <path d="M105.275 22.6442C108.415 19.5147 113.507 19.5147 116.648 22.6442C113.508 25.7737 108.416 25.7737 105.275 22.6442Z" fill="#00193D"/>
      </svg>
        
      <svg className='logo2' width="102" height="24" viewBox="0 0 102 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3224_11010)">
<path d="M0 0C5.94034 0 10.7558 4.80643 10.7558 10.7356C4.81546 10.7356 0 5.9292 0 0Z" fill="#13890E"/>
<path d="M13.2893 0H22.1551C23.1992 0 24.0451 0.844834 24.0451 1.88648V10.7356H13.2893V0Z" fill="#F58634"/>
<path d="M0 13.2644H10.7558V24H1.89002C0.846421 24 0 23.1552 0 22.1131V13.2639V13.2644Z" fill="#F58634"/>
<path d="M13.2893 13.2644H24.0451V22.1136C24.0451 23.1557 23.1987 24 22.1551 24H13.2893V13.2644Z" fill="#F58634"/>
<path d="M31.2656 4.04639H34.1055L36.5822 12.2725C36.8169 13.125 37.0521 13.9986 37.2868 15.3624C37.5431 13.977 37.7778 13.1672 38.0553 12.2725L40.5104 4.04639H43.5209L45.9337 12.2725C46.1901 13.1461 46.4247 14.0413 46.6811 15.3624C46.9802 13.9132 47.1937 13.0818 47.4284 12.2936L49.948 4.04639H52.7238L47.9839 19.6249H45.3364L42.0056 8.28736L38.6107 19.6249H35.9205L31.2661 4.04639H31.2656Z" fill="#00193D"/>
<path d="M52.2535 14.3612C52.2535 11.0582 54.4099 8.77759 57.5056 8.77759C60.6013 8.77759 62.6726 10.8873 62.6726 14.1692V14.9578L54.7302 14.979C54.9221 16.8333 55.9046 17.7707 57.634 17.7707C59.0648 17.7707 60.004 17.2168 60.3027 16.215H62.7154C62.2672 18.5167 60.3455 19.902 57.5912 19.902C54.4527 19.902 52.2535 17.6219 52.2535 14.3612ZM54.7942 13.3166H60.0891C60.0891 11.8675 59.0854 10.9084 57.5273 10.9084C55.9691 10.9084 55.0505 11.7393 54.7942 13.3166Z" fill="#00193D"/>
<path d="M73.6463 14.4039C73.6463 11.122 75.5676 8.77757 78.7494 8.77757C80.2224 8.77757 81.5247 9.39536 82.187 10.4399V3.76953H84.7705V19.625H82.3793L82.2086 17.9843C81.568 19.1988 80.2017 19.902 78.6003 19.902C75.5474 19.902 73.6468 17.6646 73.6468 14.4039H73.6463ZM82.1653 14.3184C82.1653 12.4219 80.9909 11.1004 79.1764 11.1004C77.3619 11.1004 76.2515 12.443 76.2515 14.3184C76.2515 16.1939 77.3614 17.5154 79.1764 17.5154C80.9914 17.5154 82.1653 16.2155 82.1653 14.3184Z" fill="#00193D"/>
<path d="M86.8625 14.3612C86.8625 11.0582 89.0189 8.77759 92.1146 8.77759C95.2103 8.77759 97.2816 10.8873 97.2816 14.1692V14.9578L89.3392 14.979C89.5316 16.8333 90.5136 17.7707 92.243 17.7707C93.6738 17.7707 94.613 17.2168 94.9117 16.215H97.3244C96.8762 18.5167 94.9545 19.902 92.2002 19.902C89.0617 19.902 86.8625 17.6219 86.8625 14.3612ZM89.4032 13.3166H94.6981C94.6981 11.8675 93.6944 10.9084 92.1358 10.9084C90.5771 10.9084 89.659 11.7393 89.4027 13.3166H89.4032Z" fill="#00193D"/>
<path d="M99.4165 19.625V3.76953H102V19.625H99.4165Z" fill="#00193D"/>
<path d="M64.6873 13.9349C66.6167 12.009 69.7451 12.009 71.675 13.9349C69.7456 15.8607 66.6172 15.8607 64.6873 13.9349Z" fill="#00193D"/>
</g>
<defs>
<clipPath id="clip0_3224_11010">
<rect width="102" height="24" fill="white"/>
</clipPath>
</defs>
</svg>


       </div> 
  )
};
